import request from '@/utils/request.js'

// 查询报告信息
export const QueryReportList = (data) => {
  return request({
    method: 'GET',
    url: 'test_report',
    params: {
      ...data
    }
  })
}
